import { Component, ElementRef, ViewChild, AfterViewInit, OnDestroy, Input, Output, OnInit, EventEmitter, Renderer } from '@angular/core';
import '@progress/kendo-ui';
import * as $ from 'jquery';
import { IHelpUploadFile } from '../../interfaces/iHelpUploadFile';
import { HelpService } from '../../services/help.service';
import { environment } from '../../environments/environment';
import { MarkdownHelper } from '../../helpers/markdown.helper';


declare var kendo: any;

@Component({
    selector: 'app-html-editor',    
    templateUrl: './html-editor.component.html',
    styleUrls: ['./html-editor.component.scss']
  })
export class HTMLEditorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('editor') textarea: ElementRef;
  @ViewChild('editorcontainer') editorContainer: ElementRef;
  @Input() value: string;
  @Input() valueImages: string;
  @Output() valueChanged = new EventEmitter<IMarkdownData>();
  public images: IMarkdownImage[] = [];
  public newImageValue: string;
  public renderedMarkdown = '';
  public newMarkdown = '';
  
  constructor(
      private hostEl: ElementRef, 
      private renderer: Renderer,
      private _helpService: HelpService
    ) { 

  }
  
  ngAfterViewInit() {
    this.textarea.nativeElement.value = this.value;
      var editor = $(this.textarea.nativeElement).kendoEditor({
          //Define Editor's options here
        //
          //Use @Input() properties to expose Editor configuration
          resizable: {
              content: true,
              toolbar: true
          },
        //   tools: [
        //     "bold",
        //     "italic",
        //     "underline",
        //     "strikethrough",
        //     "justifyLeft",
        //     "justifyCenter",
        //     "justifyRight",
        //     "justifyFull",
        //     "insertUnorderedList",
        //     "insertOrderedList",
        //     "indent",
        //     "outdent",
        //     "createLink",
        //     "unlink",
        //     "insertImage",
        //     "insertFile",
        //     "subscript",
        //     "superscript",
        //     "tableWizard",
        //     "createTable",
        //     "addRowAbove",
        //     "addRowBelow",
        //     "addColumnLeft",
        //     "addColumnRight",
        //     "deleteRow",
        //     "deleteColumn",
        //     "viewHtml",
        //     "formatting",
        //     "cleanFormatting",
        //     "fontName",
        //     "fontSize",
        //     "foreColor",
        //     "backColor",
        //     "print"
        // ],
          keyup: (args) => {
              this.valueChanged.emit({ value: args.sender.value(), valueImages: this.getAllMarkdownImages() });
          }
        //   paste: (args) => {
        //       //this.onDrop(args);
        //   }
      });
      setTimeout(() => {
        let body = $(this.editorContainer.nativeElement).find('iframe').contents().find('body');
        body.bind('drop', (event) => this.onDrop(event));
        console.log(body);
        console.log($(this.editorContainer.nativeElement));
      }, 100) 
  }

  ngOnDestroy(): void {
      kendo.destroy(this.hostEl.nativeElement);
  }

  onDrop(e: any): void {
    e.stopPropagation(); // Stops some browsers from redirecting.
    e.preventDefault();

    this.processFile(e.originalEvent.dataTransfer.files);

  }

  dragOver(e: any): void {
    e.stopPropagation(); // Stops some browsers from redirecting.
    e.preventDefault();
    var fileName = $(e.originalEvent.dataTransfer.files[0].name);
  }

  processFile(files: any): void {
    const imageType = /image.*/;
    for (let i = 0, f; (f = files[i]); i++) {
      if (f.type.match(imageType)) {
        this.encodeImageFileAsURL(f);
      }
    }
  }

  encodeImageFileAsURL(file: any): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      const index = this.images.findIndex(i => i.name === file.name);
      if (index >= 0) {
        this.images[index].value = reader.result;
      } else {
        // this.newMarkdown = '<img alt="' + file.name + '" ';
        // var updatedMarkdown = reader.result.replace('<img src', this.newMarkdown);
        this.images.push({
          name: file.name,
          value: reader.result
        });
      }
      this.updateRenderedMarkdown();
      this.updateModel();
    };

    reader.readAsDataURL(file);
  }

  private getAllMarkdownImages(): string {
    let markdownImages = '';
    this.images.forEach(i => {
      markdownImages += '\n[' + i.name + ']: ' + i.value;
    });

    return markdownImages;
  }

  replaceImage(file: IHelpUploadFile, url: string) {
    //   replace image value with URL;
    //  refresh page
  }

  updateRenderedMarkdown(): void {
    this.renderedMarkdown = MarkdownHelper.renderWithImages({ value: this.value, valueImages: this.getAllMarkdownImages() });
  }

  updateModel(): void {
    this.valueChanged.emit({ value: this.value, valueImages: this.getAllMarkdownImages() });
  }
}

export interface IMarkdownImage {
    name: string;
    value: string;
  }

  export interface IMarkdownData {
    value: string;
    valueImages: string;
  }
