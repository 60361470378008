import { MatSnackBar, MatDialog } from '@angular/material';
import { AdalService } from './../../../services/adal/adal.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { IHelp, HelpType } from './../../../interfaces/iHelp';
import { HelpService } from './../../../services/help.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import * as marked from 'marked';
import { IMarkdownData } from '../../markdown-editor/markdown-editor.component';
import { MarkdownHelper } from '../../../helpers/markdown.helper';
import { ConfirmationDialogComponent } from '../../confirmation/confirmation.component';

@Component({
  selector: 'app-help-detail',
  templateUrl: './help.detail.component.html',
  styleUrls: ['./help.detail.component.scss']
})
export class HelpDetailComponent implements OnInit {
  public help: IHelp;
  public markdownMode: string = 'editor';
  // public markdownOptions: any = {};
  public markdownOptions: any = {
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'] // default is empty, the value is case-sensitive
  };
  public isSaving: boolean = true;
  public isValidated: boolean = false;
  @ViewChild('helpForm') helpForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private _helpService: HelpService,
    private _adalService: AdalService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .switchMap((params: ParamMap) => {
        const id = parseInt(params.get('id'));
        if (id === 0) {
          return new Promise<IHelp>((resolve, reject) => {
            resolve(this._helpService.getEmpty());
          });
        } else {
          return this._helpService.getById(id).then(result => result);
        }
      })
      .subscribe((help: any) => {
        this.help = help;
        this.isSaving = false;
        this.validateForm();
      });
  }

  save(): void {
    //this.help.renderedMarkdown = marked(this.help.markdown);
    this.isSaving = true;
    this.validateForm();
    this.help.updatedBy = this._adalService.userInfo.username;
    if (this.help.id === 0) {
      this.help.createdBy = this._adalService.userInfo.username;
      this._helpService.post(this.help).then(
        (newHelp: IHelp) => {
          this._snackBar.open('Created!', 'Close message', {
            duration: 2000
          });
          this._router.navigate(['/help/' + newHelp.id]);
        },
        err => {
          console.error(err);
        }
      );
    } else {
      this._helpService.put(this.help).then(
        () => {
          this._snackBar.open('Saved!', 'Close message', {
            duration: 2000
          });
          this.isSaving = false;
          this.validateForm();
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  public onToggleStatus(): void {
    this.help.isActive = !this.help.isActive;
    this._helpService.put(this.help).then(
      () => {
        this._snackBar.open('Saved!', 'Close message', {
          duration: 2000
        });
      },
      err => {
        console.error(err);
      }
    );
  }

  delete(): void {
    this._helpService.delete(this.help.id).then(
      (newHelp: IHelp) => {
        this._snackBar.open('Deleted!', 'Close message', {
          duration: 2000
        });
        this._router.navigate(['/help/']);
      },
      err => {
        console.error(err);
      }
    );
  }

  handleMarkdownChanged(data: IMarkdownData): void {
    this.help.markdown = 'not needed';
    this.help.renderedMarkdown = data.value;
    this.help.markdownImages = data.valueImages;
  }

  public validateForm() {
    if (!this.isSaving) {
      this.isValidated = !this.isRenderedMarkdownEmpty() && !this.isDetailsEmpty();
    } else {
      this.isValidated = false;
    }
  }

  public isRenderedMarkdownEmpty(): boolean {
    return !this.help.renderedMarkdown || !this.help.markdown;
  }

  public isDetailsEmpty(): boolean {
    return !this.help.title || !this.help.category || !this.help.keywords;
  }

  onOpenConfirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to permanently delete this help topic?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        this._helpService.delete(this.help.id).then(
          (newHelpTopic: IHelp) => {
            this._snackBar.open('Deleted!', 'Close message', {
              duration: 2000
            });
            this._router.navigate(['/help/']);
          },
          err => {
            console.error(err);
          }
        );
      }
    });
  }
}
