import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { INews, NewsPostType } from './../../../interfaces/iNews';
import { NewsService } from './../../../services/news.service';
import { AdalService } from './../../../services/adal/adal.service';
import { EnumHelper } from '../../../helpers/enum.helper';
import { ILookup } from './../../../interfaces/lookup';
import * as marked from 'marked';
import { MatSnackBar, MatDialog } from '@angular/material';
import { IMarkdownData } from '../../markdown-editor/markdown-editor.component';
import { MarkdownHelper } from '../../../helpers/markdown.helper';
import { ConfirmationDialogComponent } from '../../confirmation/confirmation.component';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news.detail.component.html',
  styleUrls: ['./news.detail.component.scss']
})
export class NewsDetailComponent implements OnInit {
  public news: INews;
  public allNewsTypes: ILookup[] = [];
  public markdownMode: string = 'editor';
  // public markdownOptions: any = {};
  public markdownOptions: any = {
    hideIcons: ['Bold', 'Italic', 'Heading', 'Refrence', 'Link', 'Image', 'Ul', 'Ol', 'Code', 'TogglePreview', 'FullScreen'] // default is empty, the value is case-sensitive
  };
  public isSaving: boolean = true;
  public isValidated: boolean = false;
  @ViewChild('newsForm') newsForm: NgForm;

  constructor(
    private route: ActivatedRoute,
    private _newsService: NewsService,
    private _adalService: AdalService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.allNewsTypes = EnumHelper.toLookupDictionary(NewsPostType);
  }

  ngOnInit(): void {
    this.route.paramMap
      .switchMap((params: ParamMap) => {
        const id = parseInt(params.get('id'));
        if (id === 0) {
          return new Promise<INews>((resolve, reject) => {
            resolve(this._newsService.getEmpty());
          });
        } else {
          return this._newsService.getById(id).then(result => result);
        }
      })
      .subscribe((news: any) => {
        this.news = news;
        this.isSaving = false;
        this.validateForm();
      });
  }

  save(): void {
    //this.news.article = marked(this.news.markdown);
    this.isSaving = true;
    this.validateForm();
    this.news.updatedBy = this._adalService.userInfo.username;
    if (this.news.id === 0) {
      this.news.createdBy = this._adalService.userInfo.username;
      this._newsService.post(this.news).then(
        (newNewsPost: INews) => {
          this._snackBar.open('Created!', 'Close message', {
            duration: 2000
          });
          this._router.navigate(['/news/' + newNewsPost.id]);
        },
        err => {
          console.error(err);
        }
      );
    } else {
      this._newsService.put(this.news).then(
        () => {
          this._snackBar.open('Saved!', 'Close message', {
            duration: 2000
          });
          this.isSaving = false;
          this.validateForm();
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  delete(): void {
    this._newsService.delete(this.news.id).then(
      (newNewsPost: INews) => {
        this._snackBar.open('Deleted!', 'Close message', {
          duration: 2000
        });
        this._router.navigate(['/news/']);
      },
      err => {
        console.error(err);
      }
    );
  }

  public onToggleStatus(): void {
    this.news.isActive = !this.news.isActive;
    this._newsService.put(this.news).then(
      () => {
        this._snackBar.open('Saved!', 'Close message', {
          duration: 2000
        });
      },
      err => {
        console.error(err);
      }
    );
  }

  public handleMarkdownChanged(data: IMarkdownData): void {
    this.news.markdown = "not needed";
    this.news.article = data.value;
    this.news.markdownImages = data.valueImages;
    //this.news.article = MarkdownHelper.renderWithImages(data);
  }

  public validateForm() {
    if (!this.isSaving) {
      this.isValidated = !this.isNewsArticleEmpty() && !this.isDetailsEmpty();
    } else {
      this.isValidated = false;
    }
  }

  public isNewsArticleEmpty(): boolean {
    return !this.news.article || !this.news.markdown;
  }

  public isDetailsEmpty(): boolean {
    return !this.news.title || !this.news.subject;
  }

  onOpenConfirmation(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to permanently delete this news post?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'Confirm') {
        this._newsService.delete(this.news.id).then(
          (newNewsPost: INews) => {
            this._snackBar.open('Deleted!', 'Close message', {
              duration: 2000
            });
            this._router.navigate(['/news/']);
          },
          err => {
            console.error(err);
          }
        );
      }
    });
  }
}
