import { TenantsService } from './../../../services/tenants.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatStepper } from '@angular/material';
import { ITenant } from '../../../interfaces/iTenant';
import {
  ConfirmValidParentMatcher,
  errorMessages,
  CustomValidators,
  AlphaNumbericValidator
} from '../../../helpers/customValidators.helpers';
import { PatternValidator } from '@angular/forms/src/directives/validators';

@Component({
  selector: 'app-tenants-new',
  templateUrl: './tenants.new.component.html',
  styleUrls: ['./tenants.new.component.scss'],
  providers: [MatStepper]
})
export class TenantsNewDialogComponent implements OnInit {
  public formGroup: FormGroup;
  public creating = false;
  public licenseTypes: any[];
  public selectedValue: string = '';
  public confirmValidParentMatcher = new ConfirmValidParentMatcher();
  public errors = errorMessages;

  @ViewChild('stepper') private myStepper: MatStepper;
  // totalStepsCount: number;

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TenantsNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ITenant,
    private _tenantsService: TenantsService
  ) {
    this.formGroup = this._formBuilder.group({
      customerId: [data.customerId, Validators.required],
      name: [data.name, Validators.required],
      physicalAddress1: [data.physicalAddress1],
      physicalAddress2: [data.physicalAddress2],
      physicalAddressCity: [data.physicalAddressCity],
      physicalAddressState: [data.physicalAddressState],
      physicalAddressZip: [data.physicalAddressZip],
      adminFirstName: [data.adminFirstName, Validators.required],
      adminLastName: [data.adminLastName, Validators.required],
      emailGroup: this._formBuilder.group(
        {
          adminEmail: [data.adminEmail, [Validators.required, Validators.email]],
          adminEmailConfirm: ['', Validators.required]
        },
        { validator: CustomValidators.childrenEqual }
      ),
      adminUsername: [data.adminUsername, Validators.email],
      adminPhoneDirect: [data.adminPhoneDirect],
      adminPhoneCell: [data.adminPhoneCell],
      licenseType: [data.licenseType, Validators.required],
      licensesSubmitterFull: [data.licensesSubmitterFull, Validators.required],
      licensesFull: [data.licensesFull, Validators.required],
      readonlyLicenses: [data.readonlyLicenses, Validators.required],
      approverLicenses: [data.approverLicenses, Validators.required]
    });
  }
  ngOnInit(): void {
    this._tenantsService.getLicenseTypes().then(
      (res) => {
        this.licenseTypes = res;
        console.log(this.licenseTypes);
      },
      err => {
        console.log(err);
      }
    );
  }



  public goBack(stepper: MatStepper): void {
    stepper.previous();
  }
  public goForward(stepper: MatStepper): void {
    stepper.next();
  }





  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onCreateTenant(): void {
    const data = this.formGroup.value;
    console.log(data);
    data['adminEmail'] = data['emailGroup'].adminEmail;
    delete data['emailGroup'];

    if (data['adminUsername'] === '') {
      delete data['adminUsername'];
    }


    this.creating = true;
    this._tenantsService.post(data).then(newTenant => {
      this.dialogRef.close(newTenant);
      console.log(data);
    });
  }
}
