import { Component, Input, Inject, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AdalService } from '../../services/adal/adal.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.css'
  ]
})
export class AppComponent implements OnInit {
  @Input() environment = '';

  public currentYear = 1999;
  public currentVersion = '1.0.1';

  constructor(private _adalService: AdalService) {
    console.log('environment',environment.adalConfig);
    this._adalService.init(environment.adalConfig);
  }

  public ngOnInit(): void {
    console.log('Environment' + (environment.production ? ' (Prod): ' : ': ') + environment.restTarget);

    // Handle callback if this is a redirect from Azure
    this._adalService.handleWindowCallback();

    if (!this._adalService.isAuthenticated()) {
      this._adalService.login();
    }

    this.currentYear = new Date().getFullYear();
  }
}
