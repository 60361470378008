// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  restTarget: 'https://my-pm.contractlogix.com',
  adalConfig: {
    tenant: 'a5c460b6-6f06-464c-a3ce-003d212b0155',
    clientId: '29a86711-b108-4489-813b-ea8aa2c00906', // ContractLogix.TenantPortal web application in Azure
    endpoints: {
      'https://my-pm.contractlogix.com/': '29a86711-b108-4489-813b-ea8aa2c00906',
    }
  },
  apiUrl: 'https://my-pm.contractlogix.com',
  appInsightsConfig: {
    host: 'api.applicationinsights.io',
    appId: 'a7fc8347-d7cc-4986-bd58-e6cfa3774208',
    apiKey: 't58ty4r8irecproq2ednvdrnzj6dwijnwrlqpc3x'
  }
};
